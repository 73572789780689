// material-ui
import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import GovernanceActiveCard from './GovernanceActiveCard';
import GovernanceFIPHistory from './GovernanceFIPHistory';
import GovernanceGlobalStats from './GovernanceGlobalStats';

const Governance = () => {
    const theme = useTheme();
    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <GovernanceGlobalStats />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={3}>
                <GovernanceActiveCard />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={3}>
                <GovernanceFIPHistory />
            </Grid>
        </>
    );
};

export default Governance;
