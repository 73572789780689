import { Grid } from '@mui/material';
import logo from '../../assets/genlogo.png';

const Loading = () => (
    <Grid
        item
        sx={{
            backgroundColor: '#ffffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            position: 'absolute'
        }}
    >
        <img src={logo} alt="logo" width={250} height={250} />
    </Grid>
);

export default Loading;
