import MenuList from '../Sidebar/MenuList';
import { Grid } from '@mui/material';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const MobileHeader = () => (
    <>
        <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid
                container
                sx={{
                    height: 60,
                    display: 'flex',
                    borderRadius: 50,
                    background: '#fff',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: { sm: '90%', xs: '90%' },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                }}
            >
                <MenuList />
            </Grid>
        </Grid>
    </>
);

export default MobileHeader;
