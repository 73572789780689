// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Ecosystem',
    type: 'group',
    url: '/',
    children: [
        {
            id: 'governance',
            title: `DAO`,
            type: 'item',
            url: '/governance',
            breadcrumbs: false
        },
        {
            id: 'partfolio',
            title: `Portfolio`,
            type: 'item',
            url: '/portfolio',
            breadcrumbs: false
        },
        {
            id: 'earn',
            title: `Earn`,
            type: 'item',
            url: '/stake',
            breadcrumbs: false
        },
        {
            id: 'analytics',
            title: 'Details',
            type: 'item',
            url: '/analytics',
            breadcrumbs: false
        }
    ]
};

export default utilities;
