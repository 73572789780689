// theme.js
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { lightColorUI, mainColorUI } from './constants';

// Light Theme
export const lightTheme = createTheme({
    // Define your light theme properties here
    palette: {
        mode: 'light',
        primary: {
            main: '#1976D2'
        },
        secondary: {
            main: '#388E3C'
        },
        headerbuttons: {
            primary: '#46568B'
        },
        text: {
            transparent: 'transparent',
            primary: grey[100],
            secondary: grey[200],
            third: grey[300],
            invertedprimary: grey[800],
            invertedsecondary: grey[700],
            invertedthird: grey[700],
            invertedfourth: grey[600],
            brandUI: lightColorUI,
            walletbalancechange: lightColorUI,
            walletbuttons: lightColorUI,
            marketscardprimary: grey[700],
            marketscardsecondary: '#414141'
        },
        button: {
            send: mainColorUI
        },
        background: {
            default: grey[200],
            paper: grey[200]
        },
        card: {
            main: grey[50],
            secondary: grey[300],
            third: grey[400],
            ultra: grey[100],
            balancescard: grey[300],
            dashboardcard: grey[300],
            light: grey[600]
        },
        borderColor: '#21293a'
    },
    typography: {
        fontFamily: 'Inter'
    }
});

// Dark Theme
export const darkTheme = createTheme({
    // Define your dark theme properties here
    palette: {
        mode: 'light',
        primary: {
            main: '#1976D2'
        },
        secondary: {
            main: '#388E3C'
        },
        headerbuttons: {
            primary: '#46568B'
        },
        text: {
            transparent: 'transparent',
            primary: grey[100],
            secondary: grey[200],
            third: grey[300],
            invertedprimary: grey[800],
            invertedsecondary: grey[700],
            invertedthird: grey[700],
            invertedfourth: grey[600],
            brandUI: lightColorUI,
            walletbalancechange: lightColorUI,
            walletbuttons: lightColorUI,
            marketscardprimary: grey[700],
            marketscardsecondary: '#414141'
        },
        button: {
            send: mainColorUI
        },
        background: {
            default: '#f6f7fa',
            paper: '#f6f7fa'
        },
        card: {
            main: grey[50],
            secondary: grey[300],
            third: grey[400],
            ultra: grey[100],
            balancescard: grey[300],
            dashboardcard: grey[300],
            light: grey[600]
        },
        borderColor: '#21293a'
    },
    typography: {
        fontFamily: 'Inter'
    }
});
