import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Grid, Paper, TableContainer, Typography, Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';

const data = [
    { name: 'Liquidity', value: 8000000, outcome: 2 },
    { name: 'Holders', value: 1000000, outcome: 1 },
    { name: 'Burnt', value: 800000, outcome: 5 },
    { name: 'Locked', value: 9000000, outcome: 2 }
];

const GovernanceFIPHistory = () => {
    const theme = useTheme();

    return (
        <>
            <Grid
                container
                sx={{
                    p: 2,
                    display: 'flex',
                    bgcolor: '#fff',
                    borderRadius: 5,
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    width: { lg: 450, md: 450, sm: '100%', xs: '100%' }
                }}
            >
                <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 24 }}>FIP History</Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 0,
                        width: '100%',
                        height: '100%',
                        maxHeight: 300,
                        overflow: 'scroll',
                        background: 'transparent'
                    }}
                >
                    <Table sx={{ minWidth: 150, borderCollapse: 'collapse' }}>
                        <TableHead>
                            <TableRow sx={{ borderBottom: 'none' }}>
                                <TableCell sx={{ border: 'none' }}>
                                    <Typography textAlign="center" sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>
                                        Round
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: 'none' }}>
                                    <Typography textAlign="center" sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>
                                        Total Votes
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: 'none', width: 'auto', justifyContent: 'center', display: 'flex' }}>
                                    <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>Outcome</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 'none' }, borderBottom: 'none' }}
                                >
                                    <TableCell sx={{ border: 'none', display: 'flex', justifyContent: 'center' }}>
                                        <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>#{index + 1}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 'none' }}>
                                        <Typography textAlign="center" sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>
                                            {row.value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{ border: 'none' }}>
                                        <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>{row.outcome}%</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};

export default GovernanceFIPHistory;
