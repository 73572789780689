import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import logo from '../assets/walletaccicon.png';
import { useWeb3React } from '@web3-react/core';
import CloseIcon from '@mui/icons-material/Close';
import { fetchEthBalance } from 'components/wallet/sharesABI';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Typography, Modal, Box, Button, Grid, IconButton, Avatar } from '@mui/material';

function formatString(str) {
    if (str.length <= 8) {
        return str;
    }
    return `${str.slice(0, 4)}...${str.slice(-4)}`;
}

const WalletModal = ({ open, setOpen }) => {
    const theme = useTheme();
    const { account, active, deactivate } = useWeb3React();
    const [ethBalance, setEthBalance] = useState('0');
    const handleClose = () => setOpen(false);
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(true);
        setTimeout(() => {
            setIsActive(false);
        }, 2000); // 2 seconds
    };
    const handleCopy = () => {
        navigator.clipboard
            .writeText(account)
            .then(() => {
                console.log('Text successfully copied to clipboard');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    useEffect(() => {
        const loadETHBalance = async () => {
            const balance = await fetchEthBalance(account);
            setEthBalance(balance);
        };

        if (active && account) {
            loadETHBalance();
        }
    }, [account, active]);
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '70%', md: 400, lg: 400 },
                    bgcolor: theme.palette.background.default,
                    borderRadius: 7,
                    boxShadow: 24
                }}
            >
                <Grid container sx={{ position: 'absolute', justifyContent: 'right', right: 20, left: 'auto', mt: 2 }}>
                    <IconButton
                        size="small"
                        sx={{ background: grey[300] }}
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon sx={{ color: theme.palette.text.invertedprimary }} />
                    </IconButton>
                </Grid>
                <Grid container sx={{ dispaly: 'flex', mt: 3, alignItems: 'center', flexDirection: 'column' }}>
                    <Avatar src={logo} alt="logo" sx={{ width: 80, height: 80 }} />
                    <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 800, mt: 2, color: grey[900] }}>
                        {account ? formatString(account) : null}
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 600, color: grey[600] }}>
                        {(ethBalance / 1e18).toLocaleString('en-US', { maximumFractionDigits: 4, minimumFractionDigits: 2 })} BNB
                    </Typography>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 4, px: 2 }}>
                    <Button
                        onClick={() => {
                            handleCopy();
                            handleClick();
                        }}
                        startIcon={isActive ? <DoneRoundedIcon /> : <ContentCopyRoundedIcon />}
                        sx={{
                            background: '#fff',
                            textTransform: 'none',
                            borderRadius: 2,
                            color: grey[900],
                            fontWeight: 600,
                            width: '49%',
                            fontSize: 14,
                            py: 1,
                            ':hover': {
                                background: grey[100],
                                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                            }
                        }}
                    >
                        {isActive ? 'Copied!' : 'Copy Address'}
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose();
                            deactivate();
                        }}
                        startIcon={<LogoutRoundedIcon />}
                        sx={{
                            background: '#fff',
                            textTransform: 'none',
                            borderRadius: 2,
                            color: grey[900],
                            fontWeight: 600,
                            width: '49%',
                            fontSize: 14,
                            py: 1,
                            ':hover': {
                                background: grey[100],
                                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                            }
                        }}
                    >
                        Disconnect
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

// Define prop types for WalletModal
WalletModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default WalletModal;
