import { useEffect } from 'react';
import { useTheme } from '@mui/system';
import { Grid } from '@mui/material';
import GenericStats from './GenericStats';
import GenericMyCard from './GenericMyCard';

const DashboardMain = () => {
    const theme = useTheme();
    useEffect(() => {
        document.title = 'Portfolio | GenericDAO';
    });
    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <GenericStats />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={3}>
                <GenericMyCard />
            </Grid>
        </>
    );
};

export default DashboardMain;
