import { lighten, useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';
import PercentIcon from '@mui/icons-material/Percent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { brandColor, lightBrandColor } from 'themes/constants';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Grid, Box, Typography, TableBody, TableCell, TableContainer, Table, TableRow, TableHead, Paper } from '@mui/material';
import AuctionsGraph from './AuctionsGraph';

const data = [
    { name: 'Liquidity', value: 8000000, addres: '0x8...9x8f' },
    { name: 'Holders', value: 1000000, addres: 'Explorer' },
    { name: 'Burnt', value: 800000, addres: '0xF32...744O' },
    { name: 'Locked', value: 9000000, addres: '0xF32...744O' }
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const AnalyticsMain = () => {
    const theme = useTheme();
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active (hovered) slice

    // Handle mouse enter event
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    // Handle mouse leave event
    const onPieLeave = () => {
        setActiveIndex(null);
    };
    useEffect(() => {
        document.title = 'Analytics | GenericDAO';
    });
    return (
        <>
            <Grid
                container
                sx={{
                    py: 2,
                    px: 3,
                    mt: 15,
                    display: 'flex',
                    borderRadius: 5,
                    background: '#fff',
                    justifyContent: 'space-between',
                    width: { lg: '50%', xs: '97.5%' },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 24 }}>Overview</Typography>
                <Grid container display="flex">
                    <Typography sx={{ color: grey[500], fontWeight: 400, fontSize: 14 }}>
                        Earn Bitcoin and GEN interest through staking your GEN tokens.
                    </Typography>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" mt={3} mb={1}>
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            borderRadius: 2,
                            width: { lg: '49%', md: '49%', sm: '100%', xs: '100%' },
                            background: `linear-gradient(to right, #fd9961, #f87e38)`
                        }}
                    >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                            <Box
                                sx={{
                                    width: 57,
                                    height: 57,
                                    display: 'flex',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: lightBrandColor
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '55%',
                                        height: '55%',
                                        display: 'flex',
                                        borderRadius: '50%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: brandColor
                                    }}
                                >
                                    <PercentIcon sx={{ color: lightBrandColor, fontSize: 23 }} />
                                </Box>
                            </Box>
                            <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: 'auto', ml: 1.5 }}>
                                <Typography sx={{ color: grey[50], fontSize: 14 }}>Total GEN locked</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 24, fontWeight: 700 }}>23.5%</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            borderRadius: 2,
                            mt: { lg: 0, md: 0, sm: 2, xs: 2 },
                            width: { lg: '49%', md: '49%', sm: '100%', xs: '100%' },
                            background: `linear-gradient(to right, #fd9961, #f87e38)`
                        }}
                    >
                        <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                            <Box
                                sx={{
                                    width: 57,
                                    height: 57,
                                    display: 'flex',
                                    borderRadius: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: lightBrandColor
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '50%',
                                        height: '50%',
                                        display: 'flex',
                                        borderRadius: '50%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: brandColor
                                    }}
                                >
                                    <TrendingUpIcon sx={{ color: lightBrandColor, ml: -1.5, fontSize: 30, mb: -0.5 }} />
                                </Box>
                            </Box>
                            <Grid container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: 'auto', ml: 1.5 }}>
                                <Typography sx={{ color: grey[50], fontSize: 14 }}>Daily emission rate</Typography>
                                <Typography sx={{ color: grey[50], fontSize: 24, fontWeight: 700 }}>848K GEN</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    py: 2,
                    px: 3,
                    mt: 3,
                    display: 'flex',
                    borderRadius: 5,
                    background: '#fff',
                    justifyContent: 'space-between',
                    width: { lg: '50.01%', xs: '97.5%' },
                    flexDirection: { xs: 'column', md: 'row' },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    alignItems: 'center' // Vertically centers content on the row
                }}
            >
                <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 24 }}>Supply Info</Typography>
                <Typography sx={{ color: grey[900], fontWeight: 600, letterSpacing: '-1px', fontSize: 24 }}>$253,483.67</Typography>

                {/* Container for Pie Chart and Table side by side */}
                <Grid container mt={3} display="flex" alignItems="center" width="100%" sx={{ flexDirection: 'row' }}>
                    {/* Pie Chart and Details in the center */}
                    <Grid container justifyContent="center" display="flex" width="auto" position="relative">
                        <PieChart width={200} height={200}>
                            <Pie
                                data={data}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                paddingAngle={2}
                                outerRadius={90}
                                innerRadius={60}
                                cornerRadius={8}
                                onMouseEnter={onPieEnter}
                                onMouseLeave={onPieLeave}
                                fill="#8884d8"
                            >
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={activeIndex === index || activeIndex === null ? COLORS[index % COLORS.length] : grey[100]}
                                    />
                                ))}
                            </Pie>
                        </PieChart>

                        {activeIndex !== null && (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                                sx={{
                                    background: lighten(COLORS[activeIndex], 0.97),
                                    borderRadius: '50%',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    height: '56%',
                                    width: '56%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    pointerEvents: 'none'
                                }}
                            >
                                <Typography textAlign="center" sx={{ color: grey[900], fontSize: 10 }}>
                                    {data[activeIndex].value.toLocaleString('en-US', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                    })}{' '}
                                    GEN
                                </Typography>
                                <Typography textAlign="center" sx={{ color: COLORS[activeIndex], fontSize: 26, fontWeight: 800 }}>
                                    {((data[activeIndex].value / 21000000) * 100).toFixed(2)}%
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <TableContainer
                        component={Paper}
                        sx={{
                            ml: 2,
                            boxShadow: 0,
                            background: 'transparent',
                            maxWidth: { lg: '70%', md: '70%', sm: '100%', xs: '100%' }
                        }}
                    >
                        <Table sx={{ minWidth: 150, borderCollapse: 'collapse' }}>
                            <TableHead>
                                <TableRow sx={{ borderBottom: 'none' }}>
                                    <TableCell sx={{ border: 'none' }}>
                                        <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>Type</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 'none' }}>
                                        <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>Amount</Typography>
                                    </TableCell>
                                    <TableCell sx={{ border: 'none', width: 'auto' }}>
                                        <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>Value (USD)</Typography>
                                    </TableCell>
                                    <TableCell align="right" sx={{ border: 'none' }}>
                                        <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>Addresses</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 'none' }, borderBottom: 'none' }}
                                    >
                                        <TableCell component="th" scope="row" sx={{ border: 'none' }}>
                                            <Typography
                                                sx={{
                                                    color: grey[700],
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <CircleIcon sx={{ color: COLORS[index], fontSize: 14, mr: 0.3 }} />
                                                {row.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ border: 'none' }}>
                                            <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700, display: 'flex' }}>
                                                {row.value.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}{' '}
                                                GEN
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" sx={{ border: 'none' }}>
                                            <Typography sx={{ color: grey[700], fontSize: 12, fontWeight: 700 }}>
                                                $
                                                {(row.value * 0.0281349423).toLocaleString('en-US', {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2
                                                })}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ border: 'none' }}>
                                            <Typography sx={{ color: grey[500], fontSize: 12, fontWeight: 700 }}>{row.addres}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    py: 2,
                    px: 3,
                    mt: 3,
                    display: 'flex',
                    borderRadius: 5,
                    background: '#fff',
                    justifyContent: 'space-between',
                    width: { lg: '50%', xs: '97.5%' },
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 24, display: 'flex', mb: 2 }}>
                    <Box
                        sx={{
                            mr: 1,
                            width: 35,
                            height: 35,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: lightBrandColor
                        }}
                    >
                        <KeyboardDoubleArrowDownIcon sx={{ color: brandColor }} />
                    </Box>
                    Halvings Details
                </Typography>
                <AuctionsGraph />
            </Grid>
        </>
    );
};

export default AnalyticsMain;
