// import NFTContractBuild from 'contracts/NFT.json';
import Web3 from 'web3';
import { erc20Abi } from './erc20Abi';
import { LEASEABI } from './LEASEABI';

let selectedAccount;

// let nftContract;
let alxLPPair;
let erc20AlphaToken;
let alphaDAOAuctions;
let erc20USDTContract;
let pancakeswapRouter;
let erc20AlphaTokenView;
let alphaDAOCryptoLease;
let alphaDAOAuctionsView;
let alphaDAOCryptoLeaseView;
let isInitialized = false;
export const init = async () => {
    const provider = window.ethereum;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (typeof provider !== 'undefined') {
        provider
            .request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                selectedAccount = accounts[0];
                console.log(`Selected account is ${selectedAccount}`);
            })
            .catch((err) => {
                console.log(err);
            });

        window.ethereum.on('accountsChanged', (accounts) => {
            selectedAccount = accounts[0];
            console.log(`Selected account changed to ${selectedAccount}`);
        });
    }

    const web3 = new Web3(provider);
    const web3view = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');

    alxLPPair = new web3view.eth.Contract(erc20Abi, '0xB35eBe6a7E064Ed449c2Bd110c568937DfdA1d8b');
    erc20AlphaToken = new web3.eth.Contract(erc20Abi, '0x5ca2822629D66f443db806E2F480b0310e525bBB');
    alphaDAOAuctions = new web3.eth.Contract(erc20Abi, '0x5a354FB6d3978f84A7626FBBF034b44582140dE9');
    erc20USDTContract = new web3.eth.Contract(erc20Abi, '0xd389253265dd6b85C47c410EC5fF0c6A383CE949');
    pancakeswapRouter = new web3.eth.Contract(erc20Abi, '0xD99D1c33F9fC3444f8101754aBC46c52416550D1');
    alphaDAOCryptoLease = new web3.eth.Contract(LEASEABI, '0x5cc92beFa04D6397921ee2C772CC56c6C31Fde43');
    erc20AlphaTokenView = new web3view.eth.Contract(erc20Abi, '0x5ca2822629D66f443db806E2F480b0310e525bBB');
    alphaDAOAuctionsView = new web3view.eth.Contract(erc20Abi, '0x5a354FB6d3978f84A7626FBBF034b44582140dE9');
    alphaDAOCryptoLeaseView = new web3view.eth.Contract(LEASEABI, '0x5cc92beFa04D6397921ee2C772CC56c6C31Fde43');
    isInitialized = true;
};

const ERCABI = [
    // symbol
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ name: '', type: 'string' }],
        type: 'function'
    },
    {
        constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address'
            }
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance',
                type: 'uint256'
            }
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: true,
        inputs: [
            {
                name: 'owner',
                type: 'address'
            },
            {
                name: 'spender',
                type: 'address'
            }
        ],
        name: 'allowance',
        outputs: [
            {
                name: '',
                type: 'uint256'
            }
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function'
    },
    {
        constant: false,
        inputs: [
            {
                name: 'spender',
                type: 'address'
            },
            {
                name: 'amount',
                type: 'uint256'
            }
        ],
        name: 'approve',
        outputs: [
            {
                name: '',
                type: 'bool'
            }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
    }
];

const leaseAddress = '0x5cc92beFa04D6397921ee2C772CC56c6C31Fde43';

const auctionsAddress = '0x5a354FB6d3978f84A7626FBBF034b44582140dE9';

const provider = window.ethereum;
const web3 = new Web3(provider);

// BNB Balance

export const fetchEthBalance = async (accountAddress) => {
    if (!isInitialized) {
        await init();
    }

    // Fetch the balance in Wei
    const balanceInWei = await web3.eth.getBalance(accountAddress);

    return balanceInWei;
};

// USDT
export const USDTBalanceOf = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await erc20USDTContract.methods.balanceOf(account).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const balanceFromWei = Web3.utils.fromWei(balanceWei, 'ether');

    return balanceFromWei;
};

// Auctions

export const claimUSDTFromHPD = async (onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOAuctions.methods
            .claimUSDTFromHPD()
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const claimALXFromDonations = async (onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOAuctions.methods
            .claimALXFromDonations()
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const donateUSDT = async (_amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOAuctions.methods
            .donateUSDT(_amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const incrementDay = async (onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOAuctions.methods
            .incrementDay()
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const approveUSDT = async (_amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await erc20USDTContract.methods
            .approve(auctionsAddress, _amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const randomUSDT = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await alphaDAOAuctions.methods.randomUSDT(account).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const balanceALX = Web3.utils.fromWei(balanceWei, 'ether');

    const formattedBalanceNumber = parseFloat(parseFloat(balanceALX));

    return formattedBalanceNumber;
};

export const donatorBalances = async (account, day) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await alphaDAOAuctions.methods.donatorBalances(account, day).call();

    return balanceWei;
};

export const allowanceUSDT = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const allowanceWei = await erc20USDTContract.methods.allowance(account, auctionsAddress).call();

    return allowanceWei;
};

export const myClaimableALXFromDonations = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await alphaDAOAuctions.methods.myClaimableALXFromDonations(account).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const balanceALX = Web3.utils.fromWei(balanceWei, 'ether');

    const formattedBalanceNumber = parseFloat(parseFloat(balanceALX));

    return formattedBalanceNumber;
};

export const donatorTotalALXReceived = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await alphaDAOAuctions.methods.donatorTotalALXReceived(account).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const balanceALX = Web3.utils.fromWei(balanceWei, 'ether');

    const formattedBalanceNumber = parseFloat(parseFloat(balanceALX));

    return formattedBalanceNumber;
};

export const originalDonation = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await alphaDAOAuctions.methods.originalDonation(account).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const balanceALX = Web3.utils.fromWei(balanceWei, 'ether');

    const formattedBalanceNumber = parseFloat(parseFloat(balanceALX));

    return formattedBalanceNumber;
};

export const isHPDeligibleAddr = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const isHPDeligible = await alphaDAOAuctions.methods.isHPDeligibleAddr(account).call();

    return isHPDeligible;
};

export const launchTime = async () => {
    if (!isInitialized) {
        await init();
    }
    const tempLaunchTime = await alphaDAOAuctionsView.methods.launchTime().call();

    return tempLaunchTime;
};

export const currentDay = async () => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const day = await alphaDAOAuctionsView.methods.currentDay().call();

    return day;
};

export const getHPDCount = async () => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const tempHPDCount = await alphaDAOAuctionsView.methods.getHPDCount().call();

    return tempHPDCount;
};

export const dailyRatio = async (day) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const dailyRatioInWei = await alphaDAOAuctionsView.methods.dailyRatio(day).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const dailyRatioFromWei = Web3.utils.fromWei(dailyRatioInWei, 'ether');

    const formattedDailyRatio = parseFloat(parseFloat(dailyRatioFromWei));

    return formattedDailyRatio;
};

export const dailyTotalDonation = async (day) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const dailyTotalDonationInWei = await alphaDAOAuctionsView.methods.dailyTotalDonation(day).call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const dailyTotalDonationFromWei = Web3.utils.fromWei(dailyTotalDonationInWei, 'ether');

    const formattedDailyTotalDonation = parseFloat(parseFloat(dailyTotalDonationFromWei));

    return formattedDailyTotalDonation;
};

// DEX Functions

export const swapExactTokensForTokens = async (amount, tokenA, tokenB, account, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
        const receipt = await pancakeswapRouter.methods
            .swapExactTokensForTokens(amount, 0, [tokenA, tokenB], account, deadline)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const approveALXPCS = async (_amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await erc20AlphaToken.methods
            .approve('0xD99D1c33F9fC3444f8101754aBC46c52416550D1', _amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const approveUSDTPCS = async (_amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await erc20USDTContract.methods
            .approve('0xD99D1c33F9fC3444f8101754aBC46c52416550D1', _amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const allowanceALXPCS = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const allowanceWei = erc20AlphaToken.methods.allowance(account, '0xD99D1c33F9fC3444f8101754aBC46c52416550D1').call();

    return allowanceWei;
};

export const allowanceUSDTPCS = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const allowanceWei = erc20USDTContract.methods.allowance(account, '0xD99D1c33F9fC3444f8101754aBC46c52416550D1').call();

    return allowanceWei;
};

// ALX Functions

export const getReservesALX = async () => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const reserves = await alxLPPair.methods.getReserves().call();

    return reserves;
};

export const ALXbalanceOf = async (account) => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await erc20AlphaTokenView.methods.balanceOf(account).call();

    return balanceWei;
};

export const balanceOfAnyToken = async (_tokenAddress, _account) => {
    if (!isInitialized) {
        await init();
    }

    const tokenContract = new web3.eth.Contract(ERCABI, _tokenAddress);

    // Get the balance in wei
    const balanceWei = await tokenContract.methods.balanceOf(_account).call();

    return balanceWei;
};

export const approveAnyToken = async (_tokenAddress, _account, _amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    const tokenContract = new web3.eth.Contract(ERCABI, _tokenAddress);
    try {
        const receipt = await tokenContract.methods
            .approve(leaseAddress, _amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const getAllowanceAnyToken = async (_tokenAddress, _account) => {
    if (!isInitialized) {
        await init();
    }

    const tokenContract = new web3.eth.Contract(ERCABI, _tokenAddress);

    // Get the balance in wei
    const allowanceWei = await tokenContract.methods.allowance(_account, leaseAddress).call();

    return allowanceWei;
};

export const anyTokenSymbol = async (_tokenAddress) => {
    if (!isInitialized) {
        await init();
    }

    const tokenContract = new web3.eth.Contract(ERCABI, _tokenAddress);

    const symbol = await tokenContract.methods.symbol().call();

    return symbol;
};

export const totalALXburnt = async () => {
    if (!isInitialized) {
        await init();
    }

    // Get the balance in wei
    const balanceWei = await erc20AlphaTokenView.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call();

    // Convert balance from wei to USDT (1 USDT = 10^6 wei)
    const alxburnt = Web3.utils.fromWei(balanceWei, 'ether');

    // Format balance to two decimal places
    const formattedBalance = parseFloat(alxburnt);

    return formattedBalance;
};

export const totalALXCirculating = async () => {
    if (!isInitialized) {
        await init();
    }
    const totalcirculating = await erc20AlphaTokenView.methods.totalSupply().call();
    const circulatingFromWei = Web3.utils.fromWei(totalcirculating, 'ether');

    // Format balance to two decimal places
    const formattedBalance = parseFloat(circulatingFromWei);

    return formattedBalance;
};

// Lease functions

export const getOfferById = async (_id) => {
    if (!isInitialized) {
        await init();
    }
    return alphaDAOCryptoLease.methods.getOfferById(_id).call();
};

export const getLeaseById = async (_id) => {
    if (!isInitialized) {
        await init();
    }
    return alphaDAOCryptoLease.methods.getLeaseById(_id).call();
};

export const getLeaseByLessee = async (_account) => {
    if (!isInitialized) {
        await init();
    }
    return alphaDAOCryptoLease.methods.getLeasesIdsByParty(_account, 0, 50).call();
};

export const getOfferByLessor = async (_account) => {
    if (!isInitialized) {
        await init();
    }
    return alphaDAOCryptoLease.methods.getOffersByLessor(_account, 0, 50).call();
};

export const createLeaseOffer = async (_tokenA, _tokenB, _amountA, _amountB, _installments, _offset, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOCryptoLease.methods
            .createOffer(_tokenA, _tokenB, _amountA, _amountB, _installments, _offset)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const payInstallment = async (id, amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOCryptoLease.methods
            .payInstallment(id, amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const revokeOffer = async (id, amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOCryptoLease.methods
            .revokeOffer(id, amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const takeLoanOffer = async (_offerId, _amount, onTransactionSubmitted) => {
    if (!isInitialized) {
        await init();
    }

    try {
        const receipt = await alphaDAOCryptoLease.methods
            .takeLease(_offerId, _amount)
            .send({ from: selectedAccount })
            .on('transactionHash', (hash) => {
                if (onTransactionSubmitted) onTransactionSubmitted(hash);
            });
        return receipt.transactionHash; // Return the transaction hash on success
    } catch (err) {
        console.error(err);
        throw err; // Rethrow or handle as needed
    }
};

export const getLeasesInRange = async (startIndex, endIndex) => {
    if (!isInitialized) {
        await init();
    }

    const leases = await alphaDAOCryptoLease.methods.getLeasesInRange(startIndex, endIndex).call();
    return leases.map((lease) => ({
        ...lease,
        createdLoanTime: new Date(lease.createdLoanTime * 1000).toLocaleDateString()
    }));
};

export const getOfferInRange = async () => {
    if (!isInitialized) {
        await init();
    }
    return alphaDAOCryptoLeaseView.methods.getOffersInRange(0, 20).call();
};

export { web3 };
