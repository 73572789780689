import { Typography, Modal, Box, Button, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { brandColor } from 'themes/constants';
import PropTypes from 'prop-types';

// ==============================|| TransactionModal ||============================== //

const PopUpInfo = ({ open, setOpen, text }) => {
    const handleClose = () => setOpen(false);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
                    backdropFilter: 'blur(10px)',
                    bgcolor: 'rgba(40, 36, 43, 0.4)',
                    border: 0,
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4
                }}
            >
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <InfoOutlinedIcon sx={{ color: brandColor, fontSize: 50 }} />
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Typography sx={{ textAlign: 'center', fontSize: 16, color: grey[300] }}>{text}</Typography>
                </Grid>
                <Grid container sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{
                            background: `linear-gradient(45deg, #EB4308, #f9774b)`,
                            textTransform: 'none',
                            borderRadius: 1.5,
                            width: { lg: '40%', md: '45%', sm: '100%', xs: '100%' },
                            color: grey[200],
                            ':disabled': {
                                background: grey[600],
                                color: grey[100]
                            }
                        }}
                    >
                        Close
                    </Button>
                </Grid>
            </Box>
        </Modal>
    );
};

PopUpInfo.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export default PopUpInfo;
