import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { Grid, Typography, Box } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { brandColor, lightBrandColor } from 'themes/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';

const GenericStats = () => {
    const theme = useTheme();

    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    bgcolor: '#fff',
                    py: 1,
                    px: 2,
                    borderRadius: 5,
                    justifyContent: 'left',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    width: { lg: 450, md: 450, sm: '100%', xs: '100%' }
                }}
            >
                <Grid container sx={{ display: 'flex', alignItems: 'center', width: '49%' }}>
                    <Box
                        sx={{
                            width: 57,
                            height: 57,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: lightBrandColor
                        }}
                    >
                        <Box
                            sx={{
                                width: '55%',
                                height: '55%',
                                display: 'flex',
                                borderRadius: '50%',
                                alignItems: 'end',
                                justifyContent: 'center',
                                background: brandColor
                            }}
                        >
                            <TrendingUpIcon sx={{ color: lightBrandColor, ml: -1.5, fontSize: 35, mb: -0.5 }} />
                        </Box>
                    </Box>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'auto', ml: 1 }}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: grey[500], fontSize: { lg: 12, md: 12, sm: 12, xs: 11 } }}>GEN earned</Typography>
                            <InfoOutlinedIcon sx={{ fontSize: 15, color: grey[600], ml: 0.5 }} />
                        </Grid>
                        <Typography sx={{ color: grey[900], fontSize: { lg: 16, md: 16, sm: 16, xs: 14 }, fontWeight: 700 }}>$0</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ display: 'flex', alignItems: 'center', width: '49%' }}>
                    <Box
                        sx={{
                            width: 57,
                            height: 57,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: lightBrandColor
                        }}
                    >
                        <Box
                            sx={{
                                width: '55%',
                                height: '55%',
                                display: 'flex',
                                borderRadius: '50%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: brandColor
                            }}
                        >
                            <CurrencyBitcoinIcon sx={{ color: lightBrandColor, fontSize: 25 }} />
                        </Box>
                    </Box>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'auto', ml: 1 }}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: grey[500], fontSize: { lg: 12, md: 12, sm: 12, xs: 11 } }}>BTC earned</Typography>
                            <InfoOutlinedIcon sx={{ fontSize: 15, color: grey[600], ml: 0.5 }} />
                        </Grid>
                        <Typography sx={{ color: grey[900], fontSize: { lg: 16, md: 16, sm: 16, xs: 14 }, fontWeight: 700 }}>$0</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GenericStats;
