import '../BlinkingText.css';
import { useState } from 'react';
import { useTheme } from '@mui/system';
import MyRadioGroup from './RadioGroupFIP';
import { grey } from '@mui/material/colors';
import { useWeb3React } from '@web3-react/core';
import { Grid, Typography, Button } from '@mui/material';

const GovernanceActiveCard = () => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [currentVotes] = useState([0, 0, 0]);
    const [availableToVote, setAvailableToVote] = useState('0.00');
    const [vote1Fee, vote2Fee, vote5Fee] = currentVotes;
    const [setCurrentOption] = useState(1);
    const handleValueChange = (newValue) => {
        setCurrentOption(newValue);
    };

    return (
        <>
            <Grid
                container
                sx={{
                    p: 3,
                    display: 'flex',
                    borderRadius: 5,
                    background: '#fff',
                    justifyContent: 'left',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    width: { lg: 450, md: 450, sm: '100%', xs: '100%' }
                }}
            >
                <Typography sx={{ color: '#f9774b', fontWeight: 505, letterSpacing: '1px', fontSize: 18 }}>
                    #5 Generic Governance
                </Typography>
                <Typography sx={{ color: theme.palette.text.invertedthird, letterSpacing: '1px', fontSize: 18 }}>
                    Adjust the buy/sell fee of GEN tokens
                </Typography>
                <Grid container mx={1} my={1}>
                    <MyRadioGroup vote1={vote1Fee} vote2={vote2Fee} vote3={vote5Fee} onValueChange={handleValueChange} />
                </Grid>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    borderRadius={2}
                    px={2}
                    py={1.5}
                    mb={3}
                    mt={1}
                    sx={{ background: `linear-gradient(to right, #fd9961, #f87e38)` }}
                >
                    <Typography sx={{ color: grey[50], fontSize: 16, fontWeight: 500 }}>
                        Each wallet address may submit a single voting entry per round.
                    </Typography>
                </Grid>
                <Button
                    disabled={(!account && !active) || availableToVote !== 0}
                    fullWidth
                    sx={{
                        background: `linear-gradient(45deg, #EB4308, #f9774b)`,
                        textTransform: 'none',
                        color: grey[50],
                        ':disabled': { background: grey[600], color: grey[50] }
                    }}
                >
                    {active ? 'Vote' : 'Connect wallet'}
                </Button>
            </Grid>
        </>
    );
};

export default GovernanceActiveCard;
