// material-ui
import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import StakingCard from './StakingCard';
import StakingStats from './StakingStats';

const StakingMain = () => {
    const theme = useTheme();

    return (
        <>
            <Grid container display="flex" justifyContent="center" mt={15}>
                <StakingStats />
            </Grid>
            <Grid container display="flex" justifyContent="center" mt={3}>
                <StakingCard currentRound="4" title="Adjust the fees of AlphaDAO Loans platform" deadline="13.04.2024" currentFee="2" />
            </Grid>
        </>
    );
};

export default StakingMain;
