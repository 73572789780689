export const LEASEABI = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'leaseId',
                type: 'uint256'
            }
        ],
        name: 'LeaseRepaid',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'offerId',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'leaseId',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'lessee',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'createdAt',
                type: 'uint256'
            }
        ],
        name: 'LeaseTaken',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'offerId',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'lessor',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'tokenA',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'tokenB',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amountA',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'reservedPrice',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'installments',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'offset',
                type: 'uint256'
            }
        ],
        name: 'OfferCreated',
        type: 'event'
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: 'uint256',
                name: 'leaseId',
                type: 'uint256'
            },
            {
                indexed: false,
                internalType: 'address',
                name: 'lessee',
                type: 'address'
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'paymentAmount',
                type: 'uint256'
            }
        ],
        name: 'PaymentMade',
        type: 'event'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'leaseId',
                type: 'uint256'
            }
        ],
        name: 'cancelLease',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_tokenA',
                type: 'address'
            },
            {
                internalType: 'address',
                name: '_tokenB',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '_amountA',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_amountB',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_installments',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: '_offset',
                type: 'uint256'
            }
        ],
        name: 'createOffer',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'leaseId',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'paymentAmount',
                type: 'uint256'
            }
        ],
        name: 'payInstallment',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'offerId',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
            }
        ],
        name: 'revokeOffer',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'offerId',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256'
            }
        ],
        name: 'takeLease',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '_newFee',
                type: 'uint256'
            }
        ],
        name: 'updatePlatformFee',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '_governanceAddress',
                type: 'address'
            }
        ],
        stateMutability: 'nonpayable',
        type: 'constructor'
    },
    {
        inputs: [],
        name: 'dayInSeconds',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'leaseId',
                type: 'uint256'
            }
        ],
        name: 'getLeaseById',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'offerId',
                        type: 'uint256'
                    },
                    {
                        internalType: 'address',
                        name: 'lessor',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'lessee',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'amountTaken',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'installmentsPaid',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'createdLeaseTime',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'active',
                        type: 'bool'
                    },
                    {
                        internalType: 'uint256',
                        name: 'totalPaidInTokenB',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct CryptoLease.Lease',
                name: '',
                type: 'tuple'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'lessee',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'startIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'lastIndex',
                type: 'uint256'
            }
        ],
        name: 'getLeasesIdsByParty',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'startIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'endIndex',
                type: 'uint256'
            }
        ],
        name: 'getLeasesInRange',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'offerId',
                        type: 'uint256'
                    },
                    {
                        internalType: 'address',
                        name: 'lessor',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'lessee',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'amountTaken',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'installmentsPaid',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'createdLeaseTime',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'active',
                        type: 'bool'
                    },
                    {
                        internalType: 'uint256',
                        name: 'totalPaidInTokenB',
                        type: 'uint256'
                    }
                ],
                internalType: 'struct CryptoLease.Lease[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'offerId',
                type: 'uint256'
            }
        ],
        name: 'getOfferById',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'lessor',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'tokenA',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'tokenB',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'initialAmountA',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'amountA',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'reservedPrice',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'installments',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'offset',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'active',
                        type: 'bool'
                    }
                ],
                internalType: 'struct CryptoLease.Offer',
                name: '',
                type: 'tuple'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'lessor',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'startIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'lastIndex',
                type: 'uint256'
            }
        ],
        name: 'getOffersByLessor',
        outputs: [
            {
                internalType: 'uint256[]',
                name: '',
                type: 'uint256[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'startIndex',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'endIndex',
                type: 'uint256'
            }
        ],
        name: 'getOffersInRange',
        outputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'lessor',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'tokenA',
                        type: 'address'
                    },
                    {
                        internalType: 'address',
                        name: 'tokenB',
                        type: 'address'
                    },
                    {
                        internalType: 'uint256',
                        name: 'initialAmountA',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'amountA',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'reservedPrice',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'installments',
                        type: 'uint256'
                    },
                    {
                        internalType: 'uint256',
                        name: 'offset',
                        type: 'uint256'
                    },
                    {
                        internalType: 'bool',
                        name: 'active',
                        type: 'bool'
                    }
                ],
                internalType: 'struct CryptoLease.Offer[]',
                name: '',
                type: 'tuple[]'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'governanceAddress',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'leaseByParty',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [],
        name: 'leaseFee',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'leases',
        outputs: [
            {
                internalType: 'uint256',
                name: 'offerId',
                type: 'uint256'
            },
            {
                internalType: 'address',
                name: 'lessor',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'lessee',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'amountTaken',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'installmentsPaid',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'createdLeaseTime',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'active',
                type: 'bool'
            },
            {
                internalType: 'uint256',
                name: 'totalPaidInTokenB',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'offers',
        outputs: [
            {
                internalType: 'address',
                name: 'lessor',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'tokenA',
                type: 'address'
            },
            {
                internalType: 'address',
                name: 'tokenB',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: 'initialAmountA',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'amountA',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'reservedPrice',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'installments',
                type: 'uint256'
            },
            {
                internalType: 'uint256',
                name: 'offset',
                type: 'uint256'
            },
            {
                internalType: 'bool',
                name: 'active',
                type: 'bool'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address'
            },
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        name: 'offersByLessor',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256'
            }
        ],
        stateMutability: 'view',
        type: 'function'
    }
];
