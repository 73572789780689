import { useState } from 'react';
import { Radio, FormControl, FormControlLabel, RadioGroup, Typography, LinearProgress, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { brandColor, lightColorUI } from 'themes/constants';

const MyRadioGroup = ({ vote1, vote2, vote3, onValueChange }) => {
    const [value, setValue] = useState(1);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onValueChange) {
            onValueChange(event.target.value);
        }
    };

    const totalVotes = vote1 + vote2 + vote3;
    const feeOptions = [
        { percentage: '1', votes: parseInt(vote1, 10) },
        { percentage: '2', votes: parseInt(vote2, 10) },
        { percentage: '5', votes: parseInt(vote3, 10) }
    ];

    return (
        <FormControl component="fieldset" sx={{ width: '100vw' }}>
            <RadioGroup value={value} onChange={handleChange} column>
                {feeOptions.map((option) => (
                    <Box key={option.percentage} sx={{ my: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                                value={option.percentage}
                                control={
                                    <Radio
                                        sx={{
                                            color: grey[400],
                                            '&.Mui-checked': {
                                                color: brandColor // color when checked
                                            }
                                        }}
                                    />
                                }
                                label=""
                            />
                            <Box sx={{ width: '100%', position: 'relative', mr: 2 }}>
                                <LinearProgress
                                    variant="determinate"
                                    sx={{
                                        width: '100%',
                                        height: 20,
                                        borderRadius: 1,
                                        bgcolor: grey[400],
                                        '& .MuiLinearProgress-barColorPrimary': {
                                            backgroundColor: lightColorUI // Set your desired color here.
                                        }
                                    }}
                                    value={
                                        totalVotes === 0
                                            ? 0
                                            : (parseInt(option.votes, 10) /
                                                  (parseInt(vote1, 10) + parseInt(vote2, 10) + parseInt(vote3, 10))) *
                                              100
                                    }
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 10,
                                        bottom: 0,
                                        right: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'left'
                                    }}
                                >
                                    <Typography sx={{ color: grey[800] }}>{option.percentage}% Fee</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'right'
                                    }}
                                >
                                    <Typography sx={{ color: grey[800] }}>({option.votes} Votes)</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default MyRadioGroup;
