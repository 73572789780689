import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import logo from '../../../assets/genlogo.png';
import { useWeb3React } from '@web3-react/core';
import bnblogo from '../../../assets/bnblogo.svg';
import btclogo from '../../../assets/bitcoinsvg.svg';
import { Button, Grid, Typography } from '@mui/material';
import { ALXbalanceOf } from 'components/wallet/sharesABI';
import { brandColor } from 'themes/constants';

const GenericMyCard = () => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const [isBalance, setIsBalance] = useState(true);
    const [userALXBalance, setUserALXBalance] = useState(0);

    useEffect(() => {
        const fetchALXBalance = async () => {
            const balance = await ALXbalanceOf(account);
            setUserALXBalance(balance);
        };

        if (active && account) {
            fetchALXBalance();
        }
    }, [account, active]);
    return (
        <>
            <Grid container display="flex" width="auto" flexDirection="column">
                <Grid container display="flex" width="auto">
                    <Button
                        onClick={() => {
                            setIsBalance(true);
                        }}
                        sx={{
                            px: 3,
                            fontSize: 12,
                            borderRadius: 50,
                            textTransform: 'none',
                            color: isBalance ? grey[900] : grey[400],
                            background: isBalance ? '#fff' : 'transparent'
                        }}
                    >
                        Balance
                    </Button>
                    <Button
                        onClick={() => {
                            setIsBalance(false);
                        }}
                        sx={{
                            px: 3,
                            fontSize: 12,
                            borderRadius: 50,
                            textTransform: 'none',
                            color: isBalance ? grey[400] : grey[900],
                            background: isBalance ? 'transparent' : '#fff'
                        }}
                    >
                        Stakes
                    </Button>
                </Grid>
                <Grid
                    container
                    sx={{
                        mt: 2,
                        display: 'flex',
                        borderRadius: 5,
                        bgcolor: '#ffffff',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                        width: { lg: 450, md: 450, sm: '100%', xs: '100%' }
                    }}
                >
                    <Typography sx={{ color: grey[900], fontWeight: 600, fontSize: 40, px: 3, pt: 3 }}>$0</Typography>
                    <Grid container px={3}>
                        <Typography sx={{ color: grey[500], fontWeight: 500, fontSize: 14 }}>Total Balance</Typography>
                    </Grid>
                    <Grid container display="flex" alignItems="center" justifyContent="space-between" mt={4} px={3}>
                        <Grid container display="flex" alignItems="center" width="auto">
                            <img src={logo} alt="logo" width={20} height={20} />
                            <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, ml: 0.5 }}>0 GEN</Typography>
                        </Grid>
                        <Grid container display="flex" alignItems="center" width="auto">
                            <img src={bnblogo} alt="logo" width={20} height={20} />
                            <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, ml: 0.5 }}>0 BNB</Typography>
                        </Grid>
                        <Grid container display="flex" alignItems="center" width="auto">
                            <img src={btclogo} alt="logo" width={20} height={20} />
                            <Typography sx={{ color: grey[900], fontWeight: 500, fontSize: 16, ml: 0.5 }}>0 BTC</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            height: 80,
                            background: brandColor,
                            borderBottomRightRadius: 15,
                            borderBottomLeftRadius: 15,
                            mt: 5,
                            py: 2,
                            px: 2,
                            alignItems: 'center'
                        }}
                    >
                        <Typography sx={{ color: grey[50], fontWeight: 700, fontSize: 16, ml: 0.5 }}>
                            You aren’t earning any rewards!
                        </Typography>
                        <Typography sx={{ color: grey[50], fontWeight: 500, fontSize: 16, ml: 0.5 }}>
                            Stake to start earning Bitcoin and GEN yields.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GenericMyCard;
