import { grey } from '@mui/material/colors';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { brandColor } from 'themes/constants';

// Generate data for each day from Day 0 to Day 100, increasing by 200,000 each day
const data = Array.from({ length: 181 }, (v, i) => {
    const reductionFactor = Math.floor(i / 30); // Every 30 'i' reduce by half
    const pv2 = 169629 / 2 ** reductionFactor;
    return {
        name: `Day: ${i + 1}`,
        halving: reductionFactor,
        pv: pv2
    };
});

const formatYAxisTick = (value) => {
    // Convert values to 'K' for thousands, 'M' for millions
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
        return `${(value / 1000).toFixed(1)}K`;
    }
    return value;
};

const AuctionsGraph = () => {
    const theme = useTheme();

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const formattedValue = new Intl.NumberFormat('en-US').format(payload[0].value);
            const day = payload[0].payload.name;
            const halving = payload[0].payload.halving;
            return (
                <Box sx={{ p: 1, background: grey[300], borderRadius: 2 }}>
                    <Typography sx={{ color: grey[900] }}>Daily emission rate:</Typography>
                    <Typography sx={{ color: grey[900] }}>{formattedValue} GEN</Typography>
                    <Typography sx={{ color: grey[900] }}>Halving: {halving}</Typography>
                    <Typography sx={{ color: grey[900] }}>{day}</Typography>
                </Box>
            );
        }

        return null;
    };

    return (
        <>
            <ResponsiveContainer width="100%" height={320}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        bottom: 5
                    }}
                >
                    <defs>
                        <linearGradient id="colorPrice1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={brandColor} stopOpacity={1} />
                            <stop offset="95%" stopColor={brandColor} stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" strokeWidth={0.5} stroke={grey[500]} vertical={false} fill="transparent" />
                    <XAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        dataKey="name"
                        tickFormatter={(value) => (value === 'Day 0' || value === 'Day 100' ? value : '')}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.palette.text.invertedfourth }}
                        domain={[0, 200000]}
                        width={65}
                        interval={0}
                        tickFormatter={formatYAxisTick}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="pv"
                        stroke={brandColor}
                        fill="url(#colorPrice1)"
                        fillOpacity={1}
                        strokeWidth={2}
                        dot={false}
                        activeDot={{ r: 8 }}
                        isAnimationActive={false}
                        filter={(item) => item.name <= 'Day 50'}
                    />
                    <Legend formatter={(value) => (value === 'pv' ? 'Daily Emission Rate' : value)} />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default AuctionsGraph;
