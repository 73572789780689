import {
    Grid,
    Typography,
    Button,
    styled,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';
import Web3 from 'web3';
import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { FaBitcoin } from 'react-icons/fa6';
import { useState, useEffect } from 'react';
import { grey } from '@mui/material/colors';
import { brandColor } from 'themes/constants';
import { useWeb3React } from '@web3-react/core';
import FeedIcon from '@mui/icons-material/Feed';
import logo from '../../../assets/genlogo.png';
import usdtlogo from '../../../assets/UsdtLogo.png';
import PopUpInfo from 'ui-component/PopUpInfoModal';
import { ALXbalanceOf } from 'components/wallet/sharesABI';
import TransactionModal from 'ui-component/SuccessfulTransactionModal';
import RejectedTransactionModal from 'ui-component/RejectedTransactionModal';
import SubmittedTransactionModal from 'ui-component/SubmittedTransactionModal';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'right', // Align text to the right
        color: grey[900],
        fontSize: 20,
        width: 'auto',
        fontWeight: 500
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
        borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent'
        },
        '&:hover fieldset': {
            borderColor: 'transparent'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent'
        },
        '& .MuiOutlinedInput-input': {
            textAlign: 'right', // Ensure input text aligns right
            '&::placeholder': {
                fontSize: '16px', // Custom font size for placeholder
                color: 'rgba(0, 0, 0, 0.6)' // Custom color for placeholder
            }
        },
        '& .MuiInputLabel-outlined': {
            textAlign: 'right' // Align label to the right
        }
    }
});

const StakingCard = () => {
    const theme = useTheme();
    const { active, account } = useWeb3React();
    const buttonTexts = [
        ['7 days', '1x Shares'],
        ['1 month', '2x Shares'],
        ['6 months', '4x Shares'],
        ['1 year', '8x Shares']
    ];
    const [inputValue, setInputValue] = useState(0);
    const [stakes, setStakes] = useState([''], ['']);
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [stakingDays, setStakingDays] = useState(7);
    const [stakingValue, setStakingValue] = useState(0);
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [userALXBalance, setUserALXBalance] = useState(0);
    const [modalPopUpOpen, setModalPopUpOpen] = useState(false);
    const [modalRejection, setModalRejection] = useState(false);
    const [modalSubmitted, setModalSubmitted] = useState(false);
    const [popText, setPopText] = useState('');
    const [txnHash, setTxnHash] = useState('');

    const handleInputChange = (event) => {
        const web3 = new Web3();
        const value = event.target.value;
        const valueInWei = web3.utils.toWei(value, 'ether');
        if (value !== '') {
            setStakingValue(valueInWei);
            setInputValue(value);
        }
    };
    const handleFormattedDay = (day) => {
        const date = new Date((1712327815 - 86400 + day * 86400) * 1000);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: '2-digit'
        });
        return formattedDate;
    };
    // Function to handle button click
    const handleButtonClick = (index) => {
        setActiveIndex(index);
        const values = [7, 30, 180, 365];
        setStakingDays(values[index]);
    };

    const formatValue = (value) => {
        // Convert the input to a number
        const num = Number(value);
        // Format the number based on its size
        if (num >= 1000 && num < 1000000) {
            return `${(num / 1000).toFixed(2)}K`; // Thousands
        }
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(2)}M`; // Millions
        }
        return value; // Return the original value for numbers less than 1000
    };

    const buttons = buttonTexts.map((text, index) => (
        <Grid
            item
            key={index}
            onClick={() => handleButtonClick(index)}
            sx={{
                px: 1,
                py: 1,
                borderRadius: 3,
                width: '24%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: index === activeIndex ? brandColor : '#f6f7fa',
                '&:hover': {
                    cursor: 'pointer'
                }
            }}
        >
            <Typography sx={{ color: index === activeIndex ? grey[50] : grey[900], fontSize: 12 }}>{text[0]}</Typography>
            <Typography sx={{ color: index === activeIndex ? grey[50] : grey[900], fontWeight: 800, fontSize: 14, my: 1 }}>
                {text[1]}
            </Typography>
        </Grid>
    ));

    useEffect(() => {
        const fetchALXBalance = async () => {
            const balance = await ALXbalanceOf(account);
            setUserALXBalance(balance);
        };

        if (active && account) {
            fetchALXBalance();
        }
    }, [account, active, updateTrigger]);
    return (
        <>
            <Grid
                container
                sx={{
                    display: 'flex',
                    bgcolor: '#ffffff',
                    p: 3,
                    borderRadius: 5,
                    mt: 1,
                    width: { lg: 450, md: 450, sm: '100%', xs: '100%' }
                }}
            >
                <Typography sx={{ color: grey[900], fontWeight: 700, fontSize: 22 }}>Stake</Typography>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    mt={1}
                    bgcolor={theme.palette.background.paper}
                    px={2}
                    py={1}
                    borderRadius={2}
                >
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Typography sx={{ color: theme.palette.text.invertedthird, fontSize: 15 }}>Amount</Typography>
                        <Button
                            onClick={() => {
                                setInputValue(userALXBalance);
                            }}
                            sx={{
                                bgcolor: 'transparent',
                                color: theme.palette.text.invertedthird,
                                fontWeight: 300,
                                fontSize: 15,
                                textTransform: 'none'
                            }}
                        >
                            Balance: {userALXBalance.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        </Button>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Grid container width="25%" display="flex" alignItems="center">
                            <img src={logo} alt="logo" width={25} />
                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 20, fontWeight: 500, pl: 0.75 }}>
                                GEN
                            </Typography>
                        </Grid>
                        <Grid container width="70%" display="flex" justifyContent="right" alignItems="center">
                            <CustomTextField style={{ fontSize: 32 }} onChange={handleInputChange} value={inputValue} />
                        </Grid>
                    </Grid>
                    <Grid container display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 15 }}>GEN Token</Typography>
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontSize: 15 }}>~$0.00</Typography>
                    </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="space-between" alignItems="center" py={2}>
                    {buttons}
                </Grid>
                <Grid
                    container
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ border: 1, boxShadow: 1, background: '#fff', borderColor: grey[300], borderRadius: 5 }}
                >
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
                        <Typography sx={{ color: grey[500], fontWeight: 500, fontSize: 12 }}>
                            Expected Shares · {buttonTexts[activeIndex][0]}
                        </Typography>
                        <Typography
                            sx={{
                                color: grey[900],
                                fontWeight: 700,
                                fontSize: 15,
                                display: 'flex',
                                alignItems: 'center',
                                ml: 0.5,
                                mt: 0.5
                            }}
                        >
                            <FeedIcon sx={{ fontSize: 16, mr: 0.75 }} />
                            {formatValue(inputValue)}
                        </Typography>
                    </Grid>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>
                        <Typography sx={{ color: grey[500], fontWeight: 500, fontSize: 12 }}>Estimated annual yield</Typography>
                        <Typography
                            sx={{
                                color: grey[900],
                                fontWeight: 700,
                                fontSize: 15,
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center',
                                ml: 0.5,
                                mt: 0.5
                            }}
                        >
                            Flexible
                            <FaBitcoin style={{ color: '#ffa519', fontSize: 20, marginLeft: 5 }} />
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mt={3}>
                    {account && active ? (
                        <Button
                            fullWidth
                            disabled={inputValue === 0 || inputValue > userALXBalance}
                            sx={{
                                background: `linear-gradient(45deg, #EB4308, #f9774b)`,
                                textTransform: 'none',
                                borderRadius: 2,
                                color: grey[200],
                                py: 1,
                                fontSize: 18,
                                ':disabled': {
                                    background: grey[600],
                                    color: grey[100]
                                }
                            }}
                        >
                            {inputValue > userALXBalance ? 'Inssuficient ALX balance' : 'Stake'}
                        </Button>
                    ) : (
                        <Button
                            fullWidth
                            startIcon={<AccountBalanceWalletOutlinedIcon />}
                            sx={{
                                py: 1,
                                height: 50,
                                borderRadius: 50,
                                color: grey[200],
                                background: '#0e121c',
                                textTransform: 'none',
                                fontSize: 15,
                                ':disabled': {
                                    background: grey[600],
                                    color: grey[100]
                                }
                            }}
                        >
                            Connect wallet
                        </Button>
                    )}
                </Grid>
            </Grid>
            {stakes[1] && stakes[1].length > 0 && (
                <Grid container display="flex" justifyContent="center" mt={2} lg={5} sx={{ mx: { lg: 1, md: 0, sm: 0, xs: 0 } }}>
                    <Grid container sx={{ display: 'flex', bgcolor: theme.palette.card.main, p: 3, borderRadius: 2 }}>
                        <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 505, letterSpacing: '1px', fontSize: 22 }}>
                            My stakes
                        </Typography>
                        <TableContainer
                            component={Paper}
                            sx={{ mt: 2, maxHeight: '300px', bgcolor: theme.palette.card.alternative, borderRadius: 2 }}
                        >
                            <Table sx={{ minWidth: 640 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 15 }}>
                                                Amount
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 15 }}>
                                                Start Day
                                            </Typography>
                                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 15 }}>
                                                End Day
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 15 }}>
                                                Rewards
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ color: theme.palette.text.invertedprimary, fontWeight: 600, fontSize: 15 }}>
                                                Shares
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center" />
                                        <TableCell align="center" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stakes[1]
                                        .filter((stake) => stake.amount >= 0)
                                        .map((stake, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    <Grid container display="flex" justifyContent="center" alignItems="center">
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.text.invertedthird,
                                                                fontWeight: 500,
                                                                fontSize: 15,
                                                                pr: 0.5
                                                            }}
                                                        >
                                                            {(stake.amount / 1000000000000000000).toLocaleString('en-US', {
                                                                maximumFractionDigits: 2
                                                            })}
                                                        </Typography>
                                                        <img src={logo} alt="logo" width={15} />
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography
                                                        sx={{ color: theme.palette.text.invertedthird, fontWeight: 500, fontSize: 15 }}
                                                    >
                                                        {handleFormattedDay(stake.startDay)}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ color: theme.palette.text.invertedthird, fontWeight: 500, fontSize: 15 }}
                                                    >
                                                        {handleFormattedDay(stake.endDay)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Grid container display="flex" justifyContent="center" alignItems="center">
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.text.invertedthird,
                                                                fontWeight: 500,
                                                                fontSize: 15,
                                                                pr: 0.5
                                                            }}
                                                        >
                                                            {(stake.claimableUsdt / 1000000000000000000).toLocaleString('en-US', {
                                                                maximumFractionDigits: 2
                                                            })}
                                                        </Typography>
                                                        <img src={usdtlogo} alt="logo" width={15} />
                                                    </Grid>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography
                                                        sx={{ color: theme.palette.text.invertedthird, fontWeight: 500, fontSize: 15 }}
                                                    >
                                                        {(stake.xShares / 1000000000000000000).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2
                                                        })}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                        sx={{
                                                            background: `linear-gradient(45deg, #EB4308, #f9774b)`,
                                                            textTransform: 'none',
                                                            borderRadius: 1.5,
                                                            color: grey[200],
                                                            width: 80,
                                                            py: 1,
                                                            ml: -1,
                                                            ':disabled': {
                                                                background: grey[600],
                                                                color: grey[100]
                                                            }
                                                        }}
                                                    >
                                                        Close
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                                                    <Button
                                                        sx={{
                                                            background: `linear-gradient(45deg, #EB4308, #f9774b)`,
                                                            textTransform: 'none',
                                                            width: 80,
                                                            borderRadius: 1.5,
                                                            ml: -3,
                                                            color: grey[200],
                                                            py: 1,
                                                            ':disabled': {
                                                                background: grey[600],
                                                                color: grey[100]
                                                            }
                                                        }}
                                                    >
                                                        Claim
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
            <TransactionModal open={modalOpen} setOpen={setModalOpen} txnhash={txnHash} />
            <PopUpInfo open={modalPopUpOpen} setOpen={setModalPopUpOpen} text={popText} />
            <RejectedTransactionModal open={modalRejection} setOpen={setModalRejection} />
            <SubmittedTransactionModal open={modalSubmitted} setOpen={setModalSubmitted} />
        </>
    );
};

export default StakingCard;
