import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AnalyticsMain from './AnalyticsMain';

const Analytics = () => {
    useTheme();
    return (
        <Grid container sx={{ justifyContent: 'center', display: 'flex' }}>
            <AnalyticsMain />
        </Grid>
    );
};

export default Analytics;
