import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, CssBaseline, Toolbar, Slide } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import navigation from 'menu-items';

// assets
import { IconChevronRight } from '@tabler/icons';
import { grey } from '@mui/material/colors';
import Footer from './Footer';
import MobileHeader from './Header/MobileHeader';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    ...theme.typography.mainContent
}));

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();

    return (
        <>
            <CssBaseline />
            <HideOnScroll>
                <AppBar
                    position="fixed"
                    enableColorOnDark
                    sx={{
                        top: 10,
                        boxShadow: 0,
                        bottom: 'auto',
                        background: 'transparent'
                    }}
                >
                    <Toolbar>
                        <Header />
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Main theme={theme}>
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            <AppBar
                position="fixed"
                enableColorOnDark
                sx={{
                    top: 'auto',
                    bottom: 10,
                    boxShadow: 0,
                    background: 'transparent',
                    display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' }
                }}
            >
                <MobileHeader />
            </AppBar>
            <Footer />
        </>
    );
};

export default MainLayout;
