import '../BlinkingText.css';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import TimerIcon from '@mui/icons-material/Timer';
import PercentIcon from '@mui/icons-material/Percent';
import { Grid, Typography, Box } from '@mui/material';
import { brandColor, lightBrandColor } from 'themes/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const GovernanceGlobalStats = () => {
    const theme = useTheme();

    return (
        <>
            <Grid
                container
                sx={{
                    py: 1,
                    px: 2,
                    display: 'flex',
                    bgcolor: '#fff',
                    borderRadius: 5,
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                    width: { lg: 450, md: 450, sm: '100%', xs: '100%' }
                }}
            >
                <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto' }}>
                    <Box
                        sx={{
                            width: 57,
                            height: 57,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: lightBrandColor
                        }}
                    >
                        <Box
                            sx={{
                                width: '55%',
                                height: '55%',
                                display: 'flex',
                                borderRadius: '50%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: brandColor
                            }}
                        >
                            <PercentIcon sx={{ color: lightBrandColor }} />
                        </Box>
                    </Box>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'auto', ml: 2 }}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: grey[500], fontSize: 12 }}>Current Fee</Typography>
                            <InfoOutlinedIcon sx={{ fontSize: 15, color: grey[600], ml: 0.5 }} />
                        </Grid>
                        <Typography sx={{ color: grey[900], fontSize: 16, fontWeight: 700 }}>2%</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ display: 'flex', alignItems: 'center', width: 'auto', ml: 2 }}>
                    <Box
                        sx={{
                            width: 57,
                            height: 57,
                            display: 'flex',
                            borderRadius: '50%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: lightBrandColor
                        }}
                    >
                        <TimerIcon sx={{ color: brandColor, fontSize: 35 }} />
                    </Box>
                    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: 'auto', ml: 2 }}>
                        <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: grey[500], fontSize: 12 }}>Next Vote</Typography>
                            <InfoOutlinedIcon sx={{ fontSize: 15, color: grey[600], ml: 0.5 }} />
                        </Grid>
                        <Typography sx={{ color: grey[900], fontSize: 16, fontWeight: 700 }}>02:12:24</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GovernanceGlobalStats;
